<template>
  <div class="wrapper" data-view>
    <header>
      <div class="container">
        <img loading="lazy" alt="icon" class="icon-menu" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-menu.png" @click="toggleMenu" />
        <Menu :isNavOpen="isNavOpen" @toggleMenu="toggleMenu" />
        <b-row>
          <b-col cols="12" class="col-md-8 left">
            <b-col cols="12" class="col-md-6">
              <img loading="lazy" alt="icon" id="logo" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/logo.png" />
            </b-col>
          </b-col>
          <b-col cols="12" class="col-md-4 right">
            <div id="header-icons">
              <div id="empleo" class="element">
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/empleabilidad/icon-employees.png" />
                <div class="title">PORTAL DE EMPLEO</div>
                <div id="links">
                  <span><router-link to="/empleabilidad/area_empresas">Empresas</router-link></span> |
                  <span><router-link to="/empleabilidad/area_candidatos/practicas">Candidatos</router-link></span>
                </div>
              </div>
              <div class="element">
                <router-link to="/empleabilidad/asesoramiento">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/empleabilidad/icon-counceling.png" />
                  <div class="title">ASESORAMIENTO</div>
                  <div>Recursos para el empleo</div>
                </router-link>
              </div>
              <div class="element">
                <router-link to="/empleabilidad/asesoramiento">
                  <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/empleabilidad/icon-employability.png" />
                  <div>Formación específica</div>
                  <div class="title">EMPLEABILIDAD</div>
                </router-link>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>
    <div id="team">
      <div class="container">
        <div class="content">
          <b-row>
            <div id="left">
              ¿Quieres contactar con
              <div>NUESTRO EQUIPO?</div>
            </div>
            <div id="right">
              Un equipo de expertos te garantiza el asesoramiento profesional que necesitas.
              <div class="btn-see">
                <router-link to="/empleabilidad/equipo">
                  VER AL EQUIPO 
                  <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
                </router-link>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </div>
    <div id="entrepreneurship">
      <div id="bg">
        <div class="texts">
          <h2>ÁREA DE EMPRENDIMIENTO</h2>
          <p>
            Apoyamos a profesionales que deciden emprender su propia empresa. Ofrecemos
            un equipo de asesores expertos en toda España, ayudas y subvenciones,
            información estratégica en redes de Networking, encuentros de Inspiración y
            jornadas para emprendedores.
          </p>
          <router-link to="/club/emprendedores">
            <img loading="lazy" alt="icon" src="/images/work/btn-saber-mas.png" />
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/Footer';
import Menu from '../../components/team/Menu';

export default {
  metaInfo() {
    return {
      title: this.$t('empleabilidad.empleabilidad'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.empleabilidad') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.empleabilidad') + ' - Club de Ejecutivos' },
      ]
    }
  },
  components: {
    Footer,
    Menu
  },
  data() {
    return {
      isNavOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isNavOpen = !this.isNavOpen;
    },
  }
}
</script>

<style scoped lang="scss">
header {
    background: #aaa url('https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/empleabilidad/bg.jpg') no-repeat top center;
    height: 740px;
    background-size: auto;

    .container {
      height: 100%;
      padding-top: 100px;
      text-align: left;

      .left {
        flex-wrap: wrap;
        display: flex;
      }
    }

    #logo {
        width: 200px;
    }

    #header-icons {
      width: 200px;
      text-align: center;
      color: black;
      margin-left: auto;
      margin-right: 0;

      .element:not(#empleo) {
        cursor: pointer;
      }

      .element:not(#empleo):hover {
        opacity: 0.9;

        img {
          opacity: 0.7;
        }
      }

      .element {
        img {
          vertical-align: middle;
          border-style: none;
          width: 128PX;
          padding: 10px;
          border: 3px solid #807f7d;
          border-radius: 50%;
          background: #ccc;
        }

        a {
          color: black;
          text-decoration: none;
        }
        margin-bottom: 20px;

        .title {
          font-weight: 800;
          font-size: 1.2rem;
        }

        #links {
          span {
            cursor: pointer;
            
            a {
              color: black;
              text-decoration: underline;
            }
          }

          span:hover {
            a {
              text-decoration: unset;
            }
          }
        }
      }
    }

    .texts {
      margin: auto 0 0 auto;

      #one {
        color: #184a5f;
        text-transform: uppercase;
        font-size: 3rem;
        font-weight: 800;
        line-height: 3rem;
      }
      #two {
        color: #182d4b;
        font-size: 3rem;
        line-height: 3.2rem;
        font-weight: 600;
      }
      #three {
        font-size: 1.9rem;
        margin-top: 10px;
        font-weight: 300;
      }
    }
}

#team {
  background: #f2f2f2 url('https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/empleabilidad/bg-team.jpg') no-repeat center;
  height: 280px;
  color: black;

  .btn-see {
    background: #009090;
    margin: 0 auto;
    padding: 8px;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;

    a {
      color: white;
      text-decoration: none;
    }
  }
  .btn-see:hover {
    opacity: 0.7;
  }

  #left {
    margin: auto 10px auto 0;
    text-align: right;
    font-size: 2rem;
    font-weight: 300;

    div {
      font-weight: 800;
    }
  }

  #right {
    width: 25%;
    margin: auto 0 auto 10px;
  }

  .container,
  .content,
  .row {
    height: 100%;
  }

  .row {
    justify-content: center;
  }
}

#entrepreneurship {
  background: #19171c;
  color: white;

  #bg {
    background: url('/images/work/emprendedores-bg.jpg') no-repeat center;
    background-size: cover;
    height: 350px;
    margin: auto;

    .texts {
      width: 50%;
      margin: auto;
      padding-top: 30px;

      img {
        cursor: pointer;
      }

      img:hover {
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    background: url('https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/empleabilidad/bg-responsive.jpg') no-repeat top center;
    height: unset;

    .container {
      padding-top: 70px;
      text-align: center;
    }

    .texts {
      #one {
        font-size: 2.3rem;
      }
      #two {
        font-size: 2.3rem;
      }
    }

    #header-icons {
      margin: auto;
      margin-top: 20px;
    }
  }

  #team {
    #right {
      width: unset;
    }
  }

  #entrepreneurship {
    #bg {
      height: unset !important;
      padding: 10px 0 20px;
    }

    .texts {
      width: 90% !important;

      img {
        width: 100%;
      }
    }
  }
}

.icon-menu {
  width: auto;
  height: 60px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 23%;
  z-index: 9;
}

.icon-menu:hover {
  opacity: 0.8;
}
</style>